// ProductPage.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Client from "shopify-buy";
import WalletImage from "./asset/product/LIKKIM_Wallet.png";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaPlus,
  FaTwitter,
  FaTelegram,
  FaDiscord,
  FaReddit,
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaArrowRight,
  FaYoutube,
  FaShieldAlt, // 用于 EAL6
  FaMobileAlt, // 用于 3.5 Inch Display
  FaHandPointer, // 用于 MultiTouch
  FaFingerprint, // 用于 Fingerprint
  FaPlug, // 用于 Connections
  FaBatteryThreeQuarters, // 用于 Battery 700mAh
  FaCoins, // 用于 NFT
  FaMoneyBillWave, // 用于 5000 Coins
  FaServer, // 用于 Platform Support
  FaSeedling, // 用于 Seed Backup
  FaListUl, // 用于 Features
} from "react-icons/fa";
import Modal from "react-modal";
function ProductPage() {
  const [quantity, setQuantity] = useState(1);
  const [currentImage, setCurrentImage] = useState(0);
  const [variantId, setVariantId] = useState(null);
  const [variantAvailable, setVariantAvailable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const [selectedColor, setSelectedColor] = useState("");
  const [product, setProduct] = useState(null); // Added state to store product data
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [colorOptions, setColorOptions] = useState([]);
  const colorMapping = {
    Black: "#000000",
    "Champagne Gold": "#CCB68C",
    Silver: "#c0c0c0",
  };
  // 创建 ref 对象
  const productRef = useRef(null);
  const featureRef = useRef(null);
  const aboutUsRef = useRef(null);
  const faqRef = useRef(null);

  // 状态来跟踪选中的型号
  const [selectedModel, setSelectedModel] = useState(null);

  // 处理型号选择的函数
  const handleModelSelect = (model) => {
    setSelectedModel(model);
  };

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  const images = [WalletImage, WalletImage, WalletImage];

  // 初始化 Shopify 客户端
  const shopifyClient = Client.buildClient({
    domain: "shop.likkim.com",
    storefrontAccessToken: "3e4c4a173b156be8292ffba6392a486c",
  });

  useEffect(() => {
    shopifyClient.product
      .fetchByHandle("LIKKIM")
      .then((product) => {
        console.log("Variants:", product.variants);
        if (product.variants && product.variants.length > 0) {
          const firstVariant = product.variants[0];
          setVariantId(firstVariant.id);
          setVariantAvailable(firstVariant.available);

          // Collect color options
          const colors = [];
          product.variants.forEach((variant) => {
            // Assuming color is in selectedOptions
            const colorOption = variant.selectedOptions.find(
              (option) => option.name.toLowerCase() === "color"
            );

            if (colorOption) {
              colors.push(colorOption.value);
            } else {
              console.log("No color option found");
            }
          });

          setColorOptions(colors);
          setProduct(product); // Store product data in state
        } else {
          console.log("No variants found for this product.");
        }
      })
      .catch((error) => {
        console.error("Error fetching product:", error);
      });
  }, []);
  const handleColorChange = (color) => {
    if (!product || !product.variants) {
      console.error("Product data not loaded.");
      return;
    }

    setSelectedColor(color);
    console.log("Selected Color:", color);

    // 查找选中颜色的变体
    const selectedVariant = product.variants.find((variant) => {
      return variant.options && variant.options.includes(color);
    });

    if (selectedVariant) {
      setVariantId(selectedVariant.id);
      setVariantAvailable(selectedVariant.available);
      console.log(
        `Selected Variant: ${color}, Price: ${selectedVariant.price} CHF`
      );
    } else {
      console.error("No matching variant found for the selected color.");
    }
  };

  const handleBuyNow = async () => {
    // 如果变体不可售，则显示提示信息，而不跳转到结算页面
    if (!variantAvailable) {
      alert("该产品暂无库存，请稍后再试。");
      return;
    }

    try {
      console.log("Selected Variant ID:", variantId);
      console.log("Selected Color:", selectedColor);

      // 创建一个新的结算会话
      const checkout = await shopifyClient.checkout.create();
      const lineItemsToAdd = [
        {
          variantId: variantId, // 使用选中的变体 ID
          quantity: quantity, // 购买的数量
          properties: {
            color: selectedColor, // 将颜色信息作为属性传递
          },
        },
      ];

      const updatedCheckout = await shopifyClient.checkout.addLineItems(
        checkout.id,
        lineItemsToAdd
      );
      window.location.href = updatedCheckout.webUrl; // 跳转到结算页面
    } catch (error) {
      console.error("Shopify Checkout Error:", error);
      alert("结算过程中出现问题，请稍后重试。");
    }
  };

  const [faqStates, setFaqStates] = useState({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false,
  });

  const toggleFAQ = (faqKey) => {
    setFaqStates((prevStates) => ({
      ...prevStates,
      [faqKey]: !prevStates[faqKey],
    }));
  };

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="bg-neutral-900 text-white min-h-screen">
      <Header
        scrollToRef={scrollToRef}
        productRef={productRef}
        featureRef={featureRef}
        aboutUsRef={aboutUsRef}
        faqRef={faqRef}
      />
      <div className="container mx-auto px-6 md:px-12 py-12 mt-[64px]">
        {/* 标题和价格 */}
        <div className="mb-8">
          <h1 className="text-5xl font-bold">{t("Buy LIKKIM")}</h1>
          <p className="text-2xl mt-2">{t("startingPrice")}</p>
        </div>

        <div className="flex sm:flex-col flex-row gap-12 items-start">
          {/* 左侧图片展示 */}
          <div className="w-full md:w-1/2 flex justify-left items-start relative">
            <div className="w-[600px] h-[600px] bg-neutral-800 rounded-xl shadow-lg flex items-center justify-center text-neutral-500 relative overflow-hidden">
              <img
                src={images[currentImage]}
                alt={`Product Image ${currentImage + 1}`}
              />

              <button
                className="absolute left-4 top-1/2 transform -translate-y-1/2 p-3 bg-neutral-700 rounded-full"
                onClick={prevImage}
              >
                <FaChevronLeft />
              </button>
              <button
                className="absolute right-4 top-1/2 transform -translate-y-1/2 p-3 bg-neutral-700 rounded-full"
                onClick={nextImage}
              >
                <FaChevronRight />
              </button>
              <div className="absolute bottom-4 flex gap-2">
                {images.map((_, index) => (
                  <div
                    key={index}
                    className={`w-3 h-3 rounded-full ${
                      currentImage === index ? "bg-white" : "bg-neutral-600"
                    }`}
                    onClick={() => setCurrentImage(index)}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* 右侧产品详情和型号选择 */}
          <div className="w-full md:w-1/2 flex flex-col justify-between h-[600px]">
            <h2 className="text-2xl font-semibold mb-6">
              {t("Choose your model")}
            </h2>
            {/* 型号选择卡片 */}
            <div className="space-y-4">
              <div
                onClick={() => handleModelSelect("standard")}
                className={`p-6 bg-neutral-800 rounded-xl cursor-pointer hover:bg-neutral-700 ${
                  selectedModel === "standard" ? "ring-4 ring-neutral-600" : ""
                }`}
              >
                <p className="text-xl font-bold">{t("LIKKIM")}</p>
                <p className="text-neutral-400">{t("standardDescription")}</p>
                <p className="text-white mt-2">
                  Price: {product?.variants?.[0]?.price?.amount}{" "}
                  {product?.variants?.[0]?.price?.currencyCode}
                </p>
              </div>
              <div
                onClick={() => handleModelSelect("premium")}
                className={`p-6 bg-neutral-800 rounded-xl cursor-pointer hover:bg-neutral-700 ${
                  selectedModel === "premium" ? "ring-4 ring-neutral-600" : ""
                }`}
              >
                <p className="text-xl font-bold">
                  {t("LIKKIM + Premium Leather Magnetic Case")}
                </p>
                <p className="text-neutral-400">
                  {t("ColdWalletWithMagneticCase")}
                </p>
                <p className="text-white mt-2">
                  Price: {product?.variants?.[1]?.price?.amount}{" "}
                  {product?.variants?.[1]?.price?.currencyCode}
                </p>
              </div>
            </div>
            {/* Color Options */}
            <div className="space-y-4 mt-8">
              <div className="flex gap-4">
                {colorOptions.length > 0 ? (
                  // 如果有颜色选项，遍历并显示按钮
                  colorOptions.map((color, index) => (
                    <button
                      key={index}
                      onClick={() => handleColorChange(color)}
                      className={`w-8 h-8 rounded-full ${
                        selectedColor === color
                          ? "border-4 border-neutral-600"
                          : "border-4 border-transparent"
                      }`}
                      style={{
                        backgroundColor: colorMapping[color], // 使用映射的颜色值
                      }}
                    />
                  ))
                ) : (
                  // If no color options, display a default button
                  <button className="p-4 bg-neutral-800 rounded-xl text-white hover:bg-neutral-700">
                    No colors available
                  </button>
                )}
              </div>
            </div>

            {/* 数量选择器 */}
            <div className="mt-8 flex items-center mb-8">
              <button
                onClick={() => setQuantity(Math.max(1, quantity - 1))}
                className="bg-neutral-700 px-6 py-3 rounded-l-lg text-white font-bold text-base"
              >
                -
              </button>
              <span className="bg-neutral-800 px-10 py-3 text-base">
                {quantity}
              </span>
              <button
                onClick={() => setQuantity(quantity + 1)}
                className="bg-neutral-700 px-6 py-3 rounded-r-lg text-white font-bold text-base"
              >
                +
              </button>
            </div>
            {/* 按钮 */}
            <div className="flex gap-6 w-full">
              <button
                onClick={handleBuyNow}
                className="bg-neutral-600 hover:bg-neutral-500 text-white text-base px-8 py-4 rounded-xl shadow-lg flex-1"
              >
                {t("buyNow")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-neutral-800 text-white py-16 px-8 w-full">
        <div className="max-w-[1440px] mx-auto px-6">
          <h2 className="text-3xl font-bold mb-8">
            {t("technicalSpecifications")}
          </h2>
          <div className="text-center mx-auto">
            <div className="space-y-12">
              <div>
                <FaShieldAlt className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecEAL6")}</p>
              </div>
              <div>
                <FaMobileAlt className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpec3_5InchDisplay")}</p>
              </div>
              <div>
                <FaHandPointer className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecMultiTouch")}</p>
              </div>
              <div>
                <FaFingerprint className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecFingerprint")}</p>
              </div>
              <div>
                <FaMobileAlt className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecHapticFeedback")}</p>
              </div>
              <div>
                <FaPlug className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecConnections")}</p>
              </div>
              <div>
                <FaBatteryThreeQuarters className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecBattery700mAh")}</p>
              </div>
              <div>
                <FaCoins className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecNFT")}</p>
              </div>
              <div>
                <FaMoneyBillWave className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpec5000Coins")}</p>
              </div>
              <div>
                <FaServer className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecPlatformSupport")}</p>
              </div>
              <div>
                <FaSeedling className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecSeedBackup")}</p>
              </div>
              <div>
                <FaListUl className="mx-auto w-16 h-16" />
                <p className="mt-2">{t("techSpecFeatures")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-neutral-900 text-white py-16 px-8 w-full">
        <div className="max-w-[1440px] mx-auto px-6">
          <h2 className="text-3xl font-bold mb-8">{t("faq")}</h2>
          <div className="w-full flex items-center justify-center flex-col">
            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq1")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq1 ? "rotated-icon" : ""
                  }`}
                />
                {t("WhatIsColdWallet")}
              </div>

              <AnimatePresence>
                {faqStates.faq1 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("ColdWalletDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq2")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq2 ? "rotated-icon" : ""
                  }`}
                />
                {t("HowToSetUpColdWallet")}
              </div>

              <AnimatePresence>
                {faqStates.faq2 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("ColdWalletSetupDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq3")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq3 ? "rotated-icon" : ""
                  }`}
                />
                {t("CryptocurrenciesCompatibleWithColdWallet")}
              </div>

              <AnimatePresence>
                {faqStates.faq3 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("CryptocurrenciesCompatibilityDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq4")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq4 ? "rotated-icon" : ""
                  }`}
                />
                {t("HowToSecureColdWallet")}
              </div>

              <AnimatePresence>
                {faqStates.faq4 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("ColdWalletSecurityDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq5")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq5 ? "rotated-icon" : ""
                  }`}
                />
                {t("ColdWalletNotWorking")}
              </div>

              <AnimatePresence>
                {faqStates.faq5 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("ColdWalletErrorDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
      {/* Stay Connected and Newsletter Section */}
      <div className="bg-neutral-800 text-white py-16 px-8 w-full">
        <div className="max-w-[1440px] mx-auto px-6 flex flex-row sm:flex-col justify-between items-center">
          {/* Left Side - Stay Connected */}
          <div className="max-w-lg mb-8 md:mb-0">
            <h2 className="text-3xl font-bold mb-4">Stay in touch</h2>
            <p>Announcements can be found in our blog.</p>
            <p className="mt-2">
              Press contact:{" "}
              <a href="mailto:info@likkim.com" className="text-blue-400">
                info@likkim.com
              </a>
            </p>

            <div className="flex flex-wrap justify-start mt-4 gap-4">
              <a
                href="https://x.com/LIKKIMwallet"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="text-white bg-neutral-600 rounded-full p-3 w-12 h-12" />
              </a>
              <a
                href="https://t.me/+q9j351SAY8hlMDJl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegram className="text-white bg-neutral-600 rounded-full p-3 w-12 h-12" />
              </a>
              <a
                href="https://discord.gg/59hKBX2daq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDiscord className="text-white bg-neutral-600 rounded-full p-3 w-12 h-12" />
              </a>
              <a
                href="https://www.reddit.com/user/Ok_Bass_6829/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaReddit className="text-white bg-neutral-600 rounded-full p-3 w-12 h-12" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="text-white bg-neutral-600 rounded-full p-3 w-12 h-12" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61570753106156"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="text-white bg-neutral-600 rounded-full p-3 w-12 h-12" />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="text-white bg-neutral-600 rounded-full p-3 w-12 h-12" />
              </a>
              <a
                href="https://www.youtube.com/@LukkeyAG"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className="text-white bg-neutral-600 rounded-full p-3 w-12 h-12" />
              </a>
            </div>
          </div>

          {/* Right Side - Newsletter Subscription */}
          <div className="max-w-lg">
            <h2 className="text-3xl font-bold mb-4">
              Subscribe to our newsletter
            </h2>
            <p>
              New coins supported, blog updates, and exclusive offers directly
              in your inbox
            </p>
            <div className="mt-4 flex items-center">
              <input
                type="email"
                placeholder="Your email"
                className="p-4 rounded-full bg-neutral-900 text-white border border-neutral-600 outline-none flex-grow placeholder-neutral-500"
              />
              <button
                className="p-4 rounded-full bg-white text-black flex items-center justify-center gap-2 ml-4 min-w-[180px]"
                onClick={openModal} // 点击按钮时打开弹窗
              >
                <span>Subscribe to newsletter</span>
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
        {/* 弹窗 */}
        <Modal
          isOpen={isModalOpen} // 控制弹窗的显示
          onRequestClose={closeModal} // 点击外部关闭弹窗
          contentLabel="Email Confirmation"
          className="w-[80%] max-w-[600px] mx-auto p-6 bg-white rounded-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <h2 className="text-2xl font-semibold mb-4">Confirm your email</h2>
          <p className="mb-4">
            Please open the confirmation email sent to you and click the
            verification link. Please be sure to check your spam folder too.
          </p>
          <p className="mb-6">You will be able to unsubscribe at any time.</p>
          <button
            onClick={closeModal} // 关闭弹窗
            className="px-8 py-3 bg-neutral-800 text-white rounded-full mx-auto block w-full max-w-[400px]"
          >
            Close
          </button>
        </Modal>
      </div>

      <Footer />
    </div>
  );
}

export default ProductPage;
