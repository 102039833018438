// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import HelpCenter from "./HelpCenter";
import HardwareWalletGuide from "./HardwareWalletGuide";
import WarrantySupport from "./WarrantySupport";
import Community from "./Community";
import ProductPage from "./ProductPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route
          path="/hardware-wallet-guide"
          element={<HardwareWalletGuide />}
        />
        <Route path="/warrantysupport" element={<WarrantySupport />} />
        <Route path="/community" element={<Community />} />
      </Routes>
    </Router>
  );
}

export default App;
